.e-dialog .e-dlg-header-content+.e-dlg-content {
    padding: 50px;
    border: 3px solid #F6821E;
}

.zackat-div {
    border: 2px solid #F6821E;
    border-radius: 25px;
}

.e-grid .e-headercell {
    height: 80px !important;
}

.e-numericitem {
    background-color: #F6821E !important;
}

.e-custom-btn {
    margin-left: 5px;
}

.kern {
    font-kerning: none !important;
}

.rowcellgrn {
    color: green !important;
}

.rowcellylw {
    color: #BA8E23 !important;
}

.rowcellorn {
    color: orange !important;
}

.rowcellred {
    color: red !important;
}

.e-background-colorred {
    background: red;
}

.e-date-icon {
    color: #F6821E !important;
}

.e-time-icon {
    color: #F6821E !important;
}

.e-icons {
    color: #F6821E !important;
}

a:hover {
    background-color: #58E2F7 !important;
}

.e-grid .e-filterbarcell .e-input-group.e-input-focus {
    border: 1px dashed black !important;
    border-radius: 30px 20px !important;
}

.e-grid .e-icon-filter::before {
    content: '\e81e' !important;
}

/* Custom color Switch */
.e-switch-wrapper.bar-color .e-switch-inner.e-switch-active,
.e-switch-wrapper.bar-color:hover .e-switch-inner.e-switch-active .e-switch-on {
    background-color: #366976;
    border-color: #366976;
}

li {
    list-style: none;
}

.e-switch-wrapper.bar-color .e-switch-inner,
.e-switch-wrapper.bar-color .e-switch-off {
    background-color: #F6821E;
    border-color: #F6821E;
}

.e-switch-wrapper.bar-color .e-switch-handle {
    background-color: #366976;
}

/* handle color Switch */
.e-switch-wrapper.handle-color .e-switch-handle {
    background-color: #F6821E;
}

.e-switch-wrapper.handle-color .e-switch-handle.e-switch-active {
    background-color: #F6821E
}

.e-switch-wrapper.handle-color .e-switch-inner.e-switch-active,
.e-switch-wrapper.handle-color:hover .e-switch-inner.e-switch-active .e-switch-on {
    background-color: #366976;
    border-color: #ccc;
}

.e-switch-wrapper.handle-color .e-switch-inner,
.e-switch-wrapper.handle-color .e-switch-off {
    background-color: #ccc;
    border-color: #ccc;
}

/* iOS Switch */
.e-switch-wrapper.custom-iOS .e-switch-inner.e-switch-active,
.e-switch-wrapper.custom-iOS:hover .e-switch-inner.e-switch-active .e-switch-on {
    background-color: #3df865;
    border-color: #3df665;
}

.e-switch-wrapper.custom-iOS {
    width: 42px;
    height: 24px;
}

.e-switch-wrapper.custom-iOS .e-switch-handle {
    width: 20px;
    height: 20px;
}

.e-switch-wrapper.custom-iOS .e-switch-handle.e-switch-active {
    margin-left: -22px;
}

#default_dashboard .e-close-icon {
    padding: 10px;
    float: right;
    display: none;
}

#default_dashboard .e-clear-icon::before {
    content: '\e100';
    font-size: 20px;
    float: right;
}

.riskbelow-30 {
    /* background-color: orangered;*/
    background: linear-gradient(to right, #00ff00, green);
    color: rgba(0, 0, 0, 0.0) !important;
}

.riskbelow-80 {
    background: linear-gradient(to right, #00ff00, #ffff00);
    color: rgba(0, 0, 0, 0.0) !important;
}

.riskabove-80 {
    background: linear-gradient(to right, #00ff00, #ff0000);
    color: rgba(0, 0, 0, 0.0) !important;
}

#target {
    height: 350px;
}

.control-pane .modal-dialog-target {
    min-height: 350px;
}

.property-panel-table.table-width {
    width: 100%;
}

.table-td {
    width: 60%;
}

.dialog-td-font {
    font-size: 13px;
}

.dlgbtn.dlgbtn-position {
    position: absolute;
}

.hidethis {
    display: none;
    visibility: hidden;
}

.showthis {
    display: block;
    visibility: visible;
}

.biometricsgrn {
    font-size: 50px;
    width: 200px;
    border: 1px solid silver;
    line-height: 44px;
    color: #00ff00
}

.biometricsltgrn {
    font-size: 30px;
    width: 200px;
    border: 1px solid silver;
    line-height: 44px;
    color: lightgreen
}

.biometricsorn {
    font-size: 40px;
    width: 200px;
    border: 1px solid silver;
    line-height: 44px;
    color: #F6821E;
}

.biometricsblue {
    font-size: 40px;
    width: 200px;
    border: 1px solid silver;
    line-height: 44px;
    color: blue;
}

.biometricsylw {
    font-size: 40px;
    width: 200px;
    border: 1px solid silver;
    line-height: 44px;
    color: yellow;
}

.biometricscyn {
    font-size: 40px;
    border: 1px solid silver;
    width: 200px;
    line-height: 44px;
    color: cyan;
}

.supsm {
    font-size: 10px;
    color: white;
}

.biotable {
    border: 4px solid silver;
    background-color: black;
}

.e-grid.wrap .e-columnheader .e-headercelldiv {
    margin-bottom: 2px !important;
    margin-top: 0px !important;
}